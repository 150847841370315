//  Migrated to Script Setup
<template lang="pug">
b-modal(
  ref="translationFullModal"
  v-model="show"
  :hide-header="true"
  :no-close-on-backdrop="false"
  :no-close-on-esc="false"
  body-class="no-padding"
  centered
  sticky-footer
  modal-class="translation-modal"
  :size="loading || translationNames.length === 0 ? 'md' : 'lg'"
  :hide-footer="loading || translationNames.length === 0"
)
  template(#default)
    .text-align-right
      fa(
        icon="times"
        @click="toggle"
      )
    template(v-if="loading")
      Loading
    template(v-else-if="i18nNames.length > 0 || translationNames.length > 0")
      .fullpage-translation-item.mb-2.pb-3(
        v-for="name in translationNames"
        :key="name"
      )
        label.d-flex.flex-fill.flex-row.justify-content-between.align-items-center.font-mono
          span.font-monospace {{ name }}
          span.small.text-bright-red(v-if="!data?.[`${name}_translationkey`]") Key missing. Currently unable to translate.
        div(:data-translation="data?.[`${name}_translationkey`]")
          span(v-if="data?.[name]") {{ data?.[name] }}
          span.font-italic(v-else) [Empty]

      .fullpage-translation-item.mb-2.pb-3(
        v-for="name in i18nNames"
        :key="name"
      )
        label.font-monospace i18n-t.{{ name }}
        div(:data-i18n="name")
          span(v-if="$t(name)") {{ $t(name) }}
          span.font-italic(v-else) [Empty]

    .text-center.w-100(v-else)
      fa.mb-4(
        :icon="iconMagnifyingGlass"
        size="4x"
      )
      h5.w-100.mb-5 No page-wide keys for this page

  template(#modal-footer)
    .row.w-100.align-items-center
      .col-6.justify-content-start
        .btn-group.btn-group-md
          button.btn.text-black(@click="onHide") {{ $t('formCloseButton') }}
</template>

<script setup>
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import Loading from '@layers/web/components/alerts/Loading'

import events from '@layers/web/constants/events.js'

//add mitt for envet bus
const { $event } = useNuxtApp()

const localeStore = useLocaleStore()
const { locale, pageWideTranslations } = storeToRefs(localeStore)

const tripStore = useTripStore()
const { trip } = storeToRefs(tripStore)

const translationFullModal = ref()
const show = ref(false)
const loading = ref(true)
const data = ref(null)
const translationNames = ref([])
const i18nNames = ref([])
const iconMagnifyingGlass = faMagnifyingGlass

onMounted(() => {
  $event.$on(events.showFullPageTranslationModal, toggle)
})

onBeforeUnmount(() => {
  $event.$off(events.showFullPageTranslationModal, toggle)
})

const router = useRouter()

const init = (async () => {
  loading.value = true
  data.value = null
  translationNames.value = []
  i18nNames.value = []

  const { name, params } = router.currentRoute.value

  if (name === 'category-slug') {
    try {
      const { data: categoryData } = await apiFetch(`/${locale.value}/category/${params?.slug}`)
      data.value = categoryData
      translationNames.value = [...translationNames.value, 'title', 'category_url']
    } catch {
      // do nothing
    }
  }

  const id = trip.value?.id
  if (id && name === 'triptype-slug-tab') {
    try {
      const { data: tripData } = await apiFetch(`/${locale.value}/triptype?id=${id}`)
      data.value = tripData
      translationNames.value = [...translationNames.value, 'url', 'html_title', 'name', 'short_preamble']
    } catch {
      // do nothing
    }
  }

  if (pageWideTranslations.value) {
    i18nNames.value = [
      ...(i18nNames.value || []),
      ...(pageWideTranslations.value?.[translationTypes.i18n] || []),
    ]
    translationNames.value = [
      ...(translationNames.value || []),
      ...(pageWideTranslations.value?.[translationTypes.translation] || []),
    ]
    if (data.value || pageWideTranslations.value?.translationData) {
      data.value = {
        ...(data.value || {}),
        ...(pageWideTranslations.value?.translationData || {}),
      }
    }
    console.info(pageWideTranslations.value, i18nNames.value)
  }
  if (i18nNames.value.length > 0 || (translationNames.value.length > 0 && data.value)) {
    localeStore.TOGGLE_TRANSLATION_TOOLS(true)
  }
  loading.value = false
})

const toggle = (() => {
  show.value = !show.value

  if (show.value) {
    init()
  }
})

const onHide = (() => {
  show.value = false
})
</script>

<style lang="scss">
.fullpage-translation-item {
  .cms__inner{
    margin-left: 50px !important;
  }
}
</style>
